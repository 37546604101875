<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>PPE Request</h4>
                        </b-col>
                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" squared @click="addNewRequest()">Add New</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            
                            <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                                :busy="tableData.isLoading" @row-clicked="openCard" 
                        :per-page="tableData.perPage"
                        :current-page="tableData.currentPage" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>
                                
                                <template #cell(requestDate)="row">
                                    <b-row align-v="center">
                                        <span class="mr-auto">{{row.item.requestDate | dateTimeFilter}}</span>
                                    </b-row>
                                </template>

                                <template #cell(status)="row">
                                    <b-row align-v="center">
                                        <b-badge :variant="getVariantForStatus(row.item.status)">{{row.item.status}}</b-badge>
                                    </b-row>
                                </template>

                                
                                <template #cell(actions)="row">
                                    <b-row align-v="center" align-h="end">
                                        <b-button variant="primary" size="sm" squared @click="openCard(row.item)">View</b-button>
                                    </b-row>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row align-h="center" >
                            <b-pagination
                            v-model="tableData.currentPage"
                            :total-rows="rows"
                            :per-page="tableData.perPage"
                            ></b-pagination>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
    data: () => ({
        state: 'initiliazing',
        tableData: {
            totalRecords: 10,
            perPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Date Requested',
                    key: 'requestDate',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Status',
                    key: 'status',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        }
    }),
    created(){
        this.setBreadcrumb([
            {
                text: 'PPE Request'
            },
            {
                text: 'History',
                active: true
            }
        ])
        this.getAllRequests()
        .then((res) => {
            this.tableData.dataSource = res.data
            this.tableData.isLoading = false
        })
        .catch(() => {
            this.tableData.isLoading = false
        })
    
    },
    methods:{
        ...mapActions(['getAllRequests']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        addNewRequest(){
            this.$router.push({path: '/ppe/create'})
        },
        openCard(rowItem){
            this.$store.commit('setSelectedPpeRequest', rowItem)
            this.$router.push({path: '/ppe/view'})
        },
        getVariantForStatus(rowItem){
            let itemStatus = null
            switch (rowItem) {
                case 'Received':
                    itemStatus = 'success'
                    break;
                case 'New':
                    itemStatus = 'primary'
                    break;
                case 'Denied':
                    itemStatus = 'error'
                    break;
                case 'Acquiring Items':
                    itemStatus = 'info'
                    break;
                case 'Ready For Pickup':
                    itemStatus = 'warning'
                    break;
            }

            return itemStatus
        }
    },
    computed:{
        rows()
        {
            return this.tableData.dataSource.length
        }
    }
}
</script>
